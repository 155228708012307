import axios from 'axios';

export default {
    state: {
        // departments_test: 0,
        detailsprods: {},
        detailsaplicaciones: {},
        getProductos: [],
        getProductoAplicacion: {},
        getProductosListado: {},
        getProductosListaCatalogo: {},
        productosLinks: [],
        ajusteaplicaciones: {},
    },
    getters: {
        detailsprods(state) {
            return state.detailsprods
        },
        detailsaplicaciones(state) {
            return state.detailsaplicaciones
        },
        getProductos(state) {
            return state.getProductos
        },
        getProductoAplicacion(state) {
            return state.getProductoAplicacion
        },
        getProductosListado(state) {
            return state.getProductosListado
        },
        getProductosListaCatalogo(state) {
            return state.getProductosListaCatalogo
        },
        productosLinks(state) {
            return state.productosLinks
        },
        ajusteaplicaciones(state) {
            return state.ajusteaplicaciones
        }
    },
    mutations: {
        set_detailsprods: (state, data) => {
            state.detailsprods = data
        },
        set_detailsaplicaciones: (state, data) => {
            state.detailsaplicaciones = data;
        },
        set_getProductos: (state, data) => {
            state.getProductos = data;



        },
        set_getProductoAplicacion: (state, data) => {
            state.getProductoAplicacion = data;
        },
        set_getProductosListado: (state, data) => {
            state.getProductosListado = data;
        },
        set_getProductosListaCatalogo: (state, data) => {
            state.getProductosListaCatalogo = data;
        },
        set_ajusteaplicaciones: (state, data) => {
            state.ajusteaplicaciones = data
        },
        
    },
    actions: {

        detailsprods: (context, claveprod) => {
            axios.get('/searchDetails/details/' + claveprod).then((response) => {

                console.log(response.data);
                context.commit('set_detailsprods', response.data.results)
                context.commit('set_detailsaplicaciones', response.data.results[0].aplicaciones)
                context.commit('set_ajusteaplicaciones', response.data.groupaplicaciones)
                //context.commit('set_aplicaciones', data2)

            }).catch((error) => {
                console.log(error)
            });

        },
        getProdsResults: (context, link) => {
            axios.get(link.url).then((response) => {
                context.commit('set_users', response.data)
            });
        },
        getProductos: (context) => {
            axios.get('/api/searchDetails/productos').then((response) => {
                console.log(response.data);
                context.commit('set_getProductos', response.data)
            });

        },
        getProductoAplicacion: (context, claveprod) => {
            axios.get('/api/searchDetails/getAplicaciones' + claveprod).then((response) => {
                const data2 = JSON.parse(response.data[0].aplicaciones);

                context.commit('set_getProductoAplicacion', data2)

            });

        },
        updateProducto: (context, productoData) => {
            productoData.post('/api/updateProducto/' + productoData.id).then((response) => {
                context.dispatch('getProductos')
                $('#exampleModal').modal('hide')

                window.Toast.fire({
                    icon: 'success',
                    title: 'El producto se actualizo correctamente!'
                });
            });
        },
        updateAplicacion: (context, aplicacionData) => {
            aplicacionData.post('/api/updateAplicacion/' + aplicacionData.k_parte).then((response) => {
                context.dispatch('getProductos')
                $('#exampleModal').modal('hide')

                window.Toast.fire({
                    icon: 'success',
                    title: 'La Apliacion se actualizo correctamente!'
                });
            });

        },

        getProductosListado: (context, claveprod) => {
            axios.get('/api/productoslista/' + claveprod).then((response) => {
                console.log(response);
                if (response.data.length > 0) {
                    axios.post('/api/registrabusqueda', { buscar: claveprod })
                        .then((responsePost) => {
                        })
                }
                context.commit('set_getProductosListado', response.data)
            });

        },
        getProductosListaCatalogo: (context, catalogoData) => {

            catalogoData.post('/api/catalogo/productoslistacatalogo').then((response) => {
                console.log(response.data);
                context.commit('set_getProductosListaCatalogo', response.data)
            });

        },

    },

}