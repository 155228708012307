import axios from 'axios';

export default {
    state: {
        // departments_test: 0,
        lineas: {},
    },
    getters: {
        lineas(state) {
            return state.lineas
        }
    },
    mutations: {
        set_linea: (state, data) => {
            state.lineas = data
        }
    },
    actions: {
        getLinea: (context) => {
            //axios.get(`${window.url}api/getDepartments`).then((response) => {
            axios.get(`/api/getLinea`).then((response) => {  
                
                context.commit('set_linea', response.data)
            });
        },
        storeLinea: (context, lineaData) => {
            lineaData .post('/api/storeLinea').then((response) => {
                context.dispatch('getLinea')
                $('#modallineas').modal('hide')
            });
        },
        updateLinea: (context, lineaData) => {
            lineaData.post('/api/updateLinea/' + lineaData.id).then((response) => {
                context.dispatch('getLinea')
                $('#modallineas').modal('hide')
            });
        },
        deleteLinea: (context, LineaData) => {
            if(confirm('Esta seguro que desea eliminar la Linea?')) {
                axios.post('/api/deleteLinea/' + LineaData.id).then(() => {
                    context.dispatch('getLinea')
                });
            }
        }
    },
}