import axios from 'axios';

export default {
    state: {
        // departments_test: 0,
        datspeticion: {},
        
    },
    getters: {
        datspeticion(state) {
            return state.datspeticion
        },
    },
    mutations: {
      set_datspeticion: (state, data) => {
            state.datspeticion = data
        },
    },
    actions: {
        saveDatosPeticion: (context,saveEmpresa) => {
            saveEmpresa.post('/api/admin/updatePeticion/').
                then((response) => {
                        console.log(response.data)
                        $('#modalCredenciales').modal('hide')
                        //context.dispatch('datspeticion',response.data.proceso)
                        context.commit('set_datspeticion', response.data.proceso)
                        window.Toast.fire({
                            icon: 'success',
                            title: 'Credenciales Capturadas Correctamente!'
                        });
                }).catch((error) => {
                        console.log(error)
                });
        }, 
    
    },  
}