<style scoped>
#navbar {
  position: relative;
  width: 100%;
  margin-bottom: 0;
  /* Otros estilos... */
}

.header-2 {
  position: initial;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  color: #000;
  z-index: 9990;
  flex-grow: 1;
}

/*
header .header-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  width: 100%;
  padding: 10px 20px;
  /*overflow: hidden;
  z-index: 4;
}*/
.logo {
  color: crimson;
  font-size: 25px;
}

.form {
  /*display: flex;*/
  position: sticky;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

#search {
  height: 40px;
  width: 85%;
  background: none;
  outline: none;
  border: 2px solid crimson;
  color: #333;
  padding: 0 10px;
  font-size: 17px;
  margin-left: 10px;
}

/*#search {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}
 */
label {
  height: 30%;
  width: 10%;
  background: crimson;
  color: #fff;
  cursor: pointer;
  line-height: 40px;
  text-align: center;
  font-size: 20px;

  margin-right: 10px;
  /* Añade un margen derecho para separar el label del input */
}

label:hover {
  color: #fff8;
}

#logoempresa {
  display: block;
  margin: 0 auto;
  /* Centra la imagen horizontalmente */
}

.search-container {
  position: relative;
  display: flex;
  align-items: center;
}




.result-container {
  position: absolute;
  top: 97%;
  /* Posiciona los resultados debajo del input */
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  flex-grow: 1;
  z-index: 9999;
}

.result-container ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.result-container li {
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

.result-container li:last-child {
  border-bottom: none;
}

.header-1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #000;
  width: 100%;
  padding: 10px 20px;
  overflow: hidden;
  height: 2.9rem;
  transition: opacity 0.3s ease;


}

.header-1.scroll {
  opacity: 0.8;
  transition: opacity 0.5s ease;
}



.topnav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow: auto;
  background-color: #000;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #FFF;
  overflow: hidden;
  box-sizing: border-box;
  height: 2rem;
}

.topnav a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 1rem;
  padding: .8rem;
  text-decoration: none;
  font-size: 75%;
  width: 13vw;
  -ms-flex-preferred-size: 13vw;
  flex-basis: 13vw;
  -webkit-transition: all 1.5s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
  color: white;
  z-index: 3;
}

.topnav a:hover {
  background-color: #000;
  color: transparent;
  height: 1rem;
  border-bottom: 2px solid red;
}

.topnav a.active {
  border-bottom: 3px solid red;
}

.topnav a:hover svg.svg-inline--fa {
  color: transparent;
}

.topnav a svg.svg-inline--fa:hover {
  color: transparent;
}

.topnav a span.text {
  display: none;
  padding: 0;
  z-index: -3;
}

.topnav a:hover span.text {
  display: block;
  color: white;
  font-weight: 800;
  line-height: 1;
  padding: 0;
  margin: 0;
  overflow-y: visible;
  z-index: 99;
}

[class|="svg-inline"] {
  font-size: .85rem;
  vertical-align: top;
  padding: 0;
}

.text {
  font-size: .6rem;
}

div nav.topnav li {
  display: flex;
  flex: 1;
  justify-content: center;
}


/* estilo de scroll  */
/* Estilo para navegadores WebKit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 7px;
  /* Ancho del scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #DC5F00;
  /* Color del thumb (barra de desplazamiento) */
  border-radius: 5px;
  /* Radio de borde del thumb */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Color del track (fondo del scrollbar) */
}

.cart-container {
  display: flex;
  align-items: center;
}

.loading-spinner {
  position: relative;
  margin-left: 5px;
  /* Ajusta el espacio entre el icono del carrito y el spinner */
}

.loading-text {
  position: absolute;
  top: -10px;
  /* Ajusta la posición vertical del texto encima del icono */
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  /* Ajusta el tamaño del texto según tus necesidades */
  color: red;
  /* Color del texto */
}

.cart-count {
  margin-left: 5px;
  /* Ajusta el espacio entre el icono del carrito y la cantidad */
}

.cart-link {
  text-decoration: none;
  /* Elimina el subrayado del enlace */
  color: #333;
  /* Color del texto del enlace */
}

.custom-width-busqueda {
  width: 15%;
}

.custom-width-busqueda2 {
  width: 75%;
}

.header-2.sticky {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 3px 5px #333;
  z-index: 9990;
  width: 100%;
  height: 70px;
  /* Ajusta la altura según tus necesidades */
}

/* media queries  */
@media (min-width: 768px) {
  #menu.fa-bars {
    display: none;
  }

  #menu.fa-times {
    display: none;
  }
}

@media (max-width:991px) {

  header .header-2 form #search {
    width: 100%;
  }

  #search {
    width: 70%;
    padding: 8px;
    box-sizing: border-box;
  }

  label {

    margin-top: 0;

    /* Añade un margen derecho para separar el label del input */
  }
}

@media (max-width:1360px) {
  label {
    margin-top: 0;
    /* Añade un margen derecho para separar el label del input */
  }
}

@media (max-width:768px) {
  .topnav {
    display: none;
    /* Ocultar topnav en tamaños de pantalla más pequeños */
  }

  .header-1 #menu {
    display: block;
  }

  .header-1 #navbar {
    position: fixed;

    top: -1000px;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }

  .header-1 #navbar ul {
    flex-flow: column;
    width: 250px;
    border-radius: 5px;
    padding: 30px 10px;
    background: crimson;
    box-shadow: 0 0 0 100vh rgba(0, 0, 0, .5),
      0 3px 5px #000;
  }

  .header-1 #navbar ul li {
    width: 100%;
    text-align: center;
  }

  .header-1 #navbar ul li a {
    font-size: 25px;
    display: inline;
  }

  .header-1 #navbar.nav-toggle {
    top: 30%;
    opacity: 1;
    width: 80%;
    display: block;
  }

  #search {
    width: 60%;
    padding: 8px;
    box-sizing: border-box;
  }
}

@media (max-width:500px) {

  .header-2 {
    flex-flow: column;
  }

  .header-2 form {
    padding-top: 10px;
  }

}

.basket__button-container {
  width: 100%;
  display: block;
  float: left;
}

.basket__button {
  float: left;
  text-align: center;
  color: #333333;
  font-size: 27px;
  display: inline-block;
  padding: 10px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  position: relative;
  transition: 0.2s;
}

.basket__counter {
  background-color: red;
  border-radius: 500px;
  padding: 5px;
  display: inline-block;
  position: absolute;
  color: #000;
  top: 0px;
  right: -6px;
  font-size: 12px;
  line-height: 10px;
  width: 25px;
  height: 20px;
  text-align: center;
  vertical-align: middle;
  font-weight: 800;
}

.menu-container {
  position: fixed;
  top: -10px;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: #333;
  /* Ajusta el color de fondo según tus preferencias */
  z-index: 1000;
}

.menu-container span {
  /* Ajusta los estilos de los elementos span en el menú */
  display: block;
  padding: 15px;
  /* Otros estilos... */
}

.menu-link {
  display: flex;
  align-items: center;
  /* Alinea verticalmente el contenido */
  text-decoration: none;
  color: whitesmoke;
}

.menu-link i {
  margin-right: 5px;
  /* Espacio entre el icono y el texto */
}

.styled-input-container {
  position: relative;
}

.styled-input {
  width: 300px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  font-size: 16px;
  transition: border-color 0.3s;
  padding-right: 40px;
  /* Espacio para el icono */
}

.styled-input:focus {
  border-color: #cf0a0a;
}

.styled-input::placeholder {
  color: #999;
}

.styled-input-label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
}

.search-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
}

@media (max-width: 1360px) {
  .styled-input {
    width: 250px;
    /* Espacio para el icono */
  }

  .search-icon {
    position: absolute;
    right: 38px;
    top: 50%;
  }
}

@media (max-width: 768px) {
  .header-1 {
    display: none;
  }

  .busquedaAvn {
    display: none;
  }
}



.search-icon-container {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.input-large {
  width: 100%;

  /* Escala el tamaño del input cuando está enfocado */
}
</style>
<template>
  <div id="navbar">
    <div class="header-1" :class="{ 'scroll': isSticky }">
      <nav id='navdiv'>
        <div class="topnav icon-bar" v-if="!showMenu">
          <router-link to="/home"><span class="text">Home</span><i class="fa fa-home fa-lg" aria-hidden="true"
              role="img"></i></router-link>
          <router-link to="/contacto"><span class="text">Contacto</span><i class="fa fa-envelope fa-lg" aria-hidden="true"
              role="img"></i></router-link>
          <router-link to="/ProdsMX"><span class="text">HECHO EN MÉXICO</span><i class="fa fa-map fa-lg"
              aria-hidden="true" role="img"></i></router-link>
          <a href="#"><span class="text">Blog</span><i class="fa fa-solid fa-blog fa-lg" aria-hidden="true"
              role="img"></i></a>
          <router-link to="/catalogosview"><span class="text">Catalogos</span><i class="fa fa-regular fa-book fa-lg"
              aria-hidden="true" role="img"></i></router-link>
          <router-link to="/OurBrands"><span class="text">Nuestras Marcas</span><i class="fa fa-solid fa-medium fa-lg"
              aria-hidden="true" role="img"></i></router-link>

          <div v-if="!isLoggedin"> <!-- Mostrar contenido solo si el usuario no está autenticado -->
            <router-link to="/login"><span class="text">Login</span><i class="fa fa-sign-in fa-lg" aria-hidden="true"
                role="img"></i></router-link>
          </div>
        </div>

      </nav>
    </div>
    <div v-if="showMenu" class="menu-container">
      <div class="row" style="padding:5%">
        <router-link to="/home" class="menu-link"><i class="fa fa-home fa-fw" aria-hidden="true" role="img"></i><span
            class="text">Home</span></router-link>
        <router-link to="/contacto" class="menu-link"><i class="fa fa-envelope fa-fw" aria-hidden="true"
            role="img"></i><span class="text">Contacto</span></router-link>
        <router-link to="/ProdsMX" class="menu-link"><i class="fa fa-map fa-fw" aria-hidden="true" role="img"></i><span
            class="text">HECHO EN MÉXICO</span></router-link>
        <a href="#"><span class="text">Blog</span></a>
        <router-link to="/catalogosview" class="menu-link"><i class="fa fa-regular fa-book fa-fw" aria-hidden="true"
            role="img"></i><span class="text">Catalogos</span></router-link>
        <router-link to="/OurBrands" class="menu-link"><i class="fa fa-sign-in fa-fw" aria-hidden="true"
            role="img"></i><span class="text">Nuestras Marcas</span></router-link>

        <div v-if="!isLoggedin"> <!-- Mostrar contenido solo si el usuario no está autenticado -->
          <router-link to="/login" class="menu-link"><span class="text">Login</span></router-link>
        </div>
      </div>
      <div class="row" style="padding:5%">

      </div>
    </div>
    <div class="header-2" ref="stickyHeader">

      <div class="col" style="width: 5%;margin:0;">
        <div id="menu" class="fas fa-bars" @click="navToggle"></div>
      </div>

      <div class="col" style="width: 30%;margin:0;">
        <a href="/home">
          <img :src="getAbsoluteImageUrl('/img/logo/app.png')" alt="Placholder Image 2"
            class="img-fluid img-responsive rounded product-image" style="width: 30%; height: 2%;">
        </a>
      </div>
      <div class="col busquedaAvn" style="width: 20%;">
        <div class="btn-group">
          <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"
            @click="llenadatos">
            Busqueda Avanzada
          </button>

          <ul class="dropdown-menu" style="width: 200%;">
            <li>
              <div class="container">
                <div class="row">


                  <div class="col-md-3">
                    <h6 style="font-size: 9px;"><strong>Selecciona un año</strong></h6>
                    <ul class="list-unstyled" style="width: 90%;max-height: 200px; overflow-y: auto;">


                      <!--<a class="dropdown-item" :href="'/catalogoview/' + year.year" style="font-size:8px;">{{ year.year}}</a>-->
                      <router-link tag="li" v-for="year in lineasBsuqe"
                        :to="{ name: 'catalogoview', params: { year: year.year } }"
                        style="text-decoration:none; font-size:0.75rem;color: #000;font: monospace;">
                        <div>{{
                          year.year }}</div>
                      </router-link>

                    </ul>
                  </div>
                  <div class="col-md-9">
                    <h6 style="font-size: 0.75rem;"><strong>Selecciona un linea</strong></h6>
                    <ul class="list-unstyled" style="max-height: 200px; overflow-y: auto;">

                      <li v-for="linea in lineasDispo">
                        <router-link :to="{ name: 'catalogoview', params: { year: 0, categoria: linea } }"
                          style="text-decoration:none; font-size:9px;color: #000;font: monospace;">{{ linea
                          }}</router-link>

                      </li>
                      <!--<a class="dropdown-item" :href="'/catalogoview/' + '/' + linea" style="font-size:8px;">{{ linea}}</a>-->

                    </ul>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col" style="margin:0;width: 30%;">
        <div class="styled-input-container">
          <input type="text" id="searchInput" class="styled-input" placeholder="Buscar..." autocomplete="off"
            @keyup.enter="busquedaEnter" @input="busquedarest" v-model="inputValue" @click="handleItemClick"
            :class="{ 'input-large': isInputFocused }" @focus="toggleInputSize(true)" @blur="toggleInputSize2(false)"
            style="padding-left: 50px;height: 10%;">

          <div class="search-icon-container">
            <i v-if="!showSpinnerIcon" class="fas fa-search search-icon"></i>
            <div v-else class="spinner-border spinner-border-sm text-dark spinner-icon" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>


          <div class="result-container"
            v-if="resultados.length > 0 || masbuscados.length > 0 || mejoresVentas.length > 0">
            <div class="group-container">
              <div class="group-header" v-if="resultados.length > 0">
                <span>Sugeridos</span>
              </div>
              <ul class="group-list">
                <router-link tag="li" v-for="(resultado, index) in resultados" :key="index"
                  :to="{ name: 'verprods', params: { id: resultado.k_parte } }"
                  @click="handleItemClick" style=" text-decoration: none;  color: inherit; ">
                  <div style="display: flex; align-items: center;padding: 2%;">
                    <i class="fas fa-search fa-fw" style="margin-left: 5px;"></i> <!-- Icono -->
                    <span style="margin-left: 5px;">{{ resultado.k_parte }}</span> <!-- Número de parte -->
                    <span style="margin-left: 5px; font-size: 10px; color: #333;">{{ resultado.linea }}</span>
                    <!-- Línea -->
                  </div>
                </router-link>
              </ul>
            </div>


            <div class="group-container">
              <div class="group-header" v-if="mejoresVentas.length > 0">
                <span>Más Vendidos</span>
              </div>
              <ul class="group-list">
                <router-link tag="li" v-for="(venta, index) in mejoresVentas" :key="index"
                  :to="{ name: 'verprods', params: { id: venta.k_parte } }" @click="handleItemClick"
                  style=" text-decoration: none;  color: inherit; ">
                  <div style="display: flex; align-items: center;padding: 2%;">
                    <i class="fas fa-search fa-fw" style="margin-left: 5px;"></i> <!-- Icono -->
                    <span style="margin-left: 5px;">{{ venta.k_parte }}</span> <!-- Número de parte -->
                    <!-- Línea -->
                  </div>
                </router-link>
              </ul>
            </div>
            <div class="group-container" v-if="masbuscados.length > 0">
              <div class="group-header">
                <span>Último Buscado</span>
              </div>
              <ul class="group-list">
                <router-link tag="li" v-for="(buscados, index) in masbuscados" :key="index"
                  :to="{ name: 'ListadoProductos', params: { prod: buscados.consulta } }"
                  style=" text-decoration: none;  color: inherit; ">
                  <div style="display: flex; align-items: center;padding: 2%;">
                    <i class="fas fa-search fa-fw" style="margin-left: 5px;"></i> <!-- Icono -->
                    <span style="margin-left: 5px;">{{ buscados.consulta }}</span> <!-- Número de parte -->
                    <span style="margin-left: 5px; font-size: 10px; color: #333;">{{ buscados.resultados }}
                      Resultados</span>
                    <!-- Línea -->
                  </div>
                </router-link>
              </ul>

            </div>
          </div>
        </div>
      </div>
      <div class="col" style="width: 10%;">
        <!--<div v-if="isLoggedin && cantidadEnCarrito>0">-->

        <div v-if="isLoggedin" class="cart-container">
          <!--<i class="fas fa-shopping-cart"></i>
            <span v-if="cargando" class="loading-spinner">
              <i class="fas fa-spinner fa-spin"></i>
            </span>
            <span v-else class="cart-count">
              {{ cantidadEnCarrito }}
            </span>-->
          <div class="basket__button-container">
            <div class="basket__button">
              <router-link :to="{ name: 'CartItemsList' }" class="cart-link">
                <i class="fas fa-shopping-basket"></i>
                <div class="basket__counter">{{ obtenerCantidadEnCarrito }}</div>
              </router-link>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      isLoggedin: window.Laravel.isLoggedin,
      inputValue: '',
      resultados: [],
      masbuscados: [],
      mejoresVentas: [],
      lineasBsuqe: '',
      lineasDispo: '',
      isDropdownOpen: false,
      carrito: 0,
      cantidadEnCarrito: 0,
      isSticky: false,
      isSearching: false,
      showMenu: false,
      showSpinnerIcon: false,
      isInputFocused: false
    };
  },
  computed: {
    obtenerCantidadEnCarrito() {
      return this.$store.getters.obtenerCantidadEnCarrito;
    },
  },
  mounted() {
    this.$store.dispatch('obtenerCantidadEnCarrito');
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    /*obtenerCantidadEnCarrito() {
      axios.get('/api/cartItems/cantidadEnCarrito').then((response) => {
        //console.log(response.data);
        //document.getElementById('marca').style.display ='block';       
        //$('#marca').append(response.data);
        this.cantidadEnCarrito = response.data.cantidad

      })
        .catch(function (error) {
          console.log(error);
          //document.getElementById('marca').style.display = 'none';
        });
    },*/
    toggleInputSize(isFocused) {
      this.isInputFocused = isFocused;
      this.CargaBusquedas();
    },
    toggleInputSize2(isFocused) {
      this.isInputFocused = isFocused;
      
      this.resultados = [];
      this.masbuscados = [];
      this.mejoresVentas = [];
      this.inputValue=''
    },
    CargaBusquedas(){
      axios.get(`/api/home/buscados`)
        .then((response) => {
          //this.showSpinnerIcon = false;
          //console.log(response)
          this.masbuscados = response.data.masbuscados;
          this.mejoresVentas = response.data.mejoresVentas;
        });
    },
    handleScroll() {

      const header2 = this.$refs.stickyHeader;
      const sticky = header2.offsetTop;

      this.isSticky = window.pageYOffset > sticky;
    },
    getAbsoluteImageUrl(relativeUrl) {
      // Obtén la URL base de Laravel
      const baseUrl = window.location.origin;

      // Concatena la URL base con la ruta relativa de la imagen
      return baseUrl + relativeUrl;
    },
    async busquedarest() {
      //console.log(this.inputValue)
      if (this.inputValue.length >= 4) {
        this.showSpinnerIcon = true;
        try {
          this.isSearching = true; // Indicar que se está realizando una búsqueda

          // Esperar a que se complete la búsqueda
          await axios.get(`/api/search/${this.inputValue}`)
            .then((response) => {
              this.showSpinnerIcon = false;
              this.resultados = response.data;
            });

        } catch (error) {
          console.error(error);
        } finally {
          this.isSearching = false; // Indicar que la búsqueda ha terminado
        }
      } else {
        this.resultados = [];
      }
    },

    async busquedaEnter() {
      //console.log(this.inputValue)

      if (this.inputValue.length >= 4) {
        // Esperar hasta que la búsqueda haya terminado
        if (!this.isSearching) {
          await this.busquedarest(); // Esperar a que busquedarest termine
          this.resultados = [];
          this.masbuscados = [];
          this.mejoresVentas = [];
          // Continuar con la redirección
          const prods = this.inputValue;
          this.inputValue = '';

          this.$router.push({ name: 'ListadoProductos', params: { prod: prods } });
        }
      }
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      if (this.isDropdownOpen) {
        this.loadCatalogoList();
      }
    },
    navToggle() {

      this.showMenu = !this.showMenu;
    },
    llenadatos() {
      // Puedes cargar la lista aquí si es necesario
      // console.log('entra aqui')
      axios.get(`/api/catalogo/list`)
        .then((response) => {
          //console.log(response.data)
          this.lineasBsuqe = response.data.listyear;
          this.lineasDispo = response.data.lineas;
          //console.log(this.lineasDispo)
        })
        .catch((error) => {
          console.error(error);
        });
    },

    handleItemClick() {
      // Do something with the clicked item (e.g., navigate to the details page)
      // Close the search results container
      this.resultados = [];
      this.masbuscados = [];
      this.mejoresVentas = [];
    }
  },
};
</script>
