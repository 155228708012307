require('./bootstrap');
import {createApp} from 'vue';

import App from './App.vue';
import axios from 'axios';
import router from './router';
import {store} from './stores/procedures'
import Navbar from './components/navegation/Navbar.vue';
import PrimeVue from 'primevue/config';
import roductcard from './components/viewsCart/ProductCard.vue';
import Form from 'vform';
window.Form = Form;
import Multiselect from '@vueform/multiselect';
import '@vueform/multiselect/themes/default.css';
import Tooltip from 'primevue/tooltip';
import { VueReCaptcha } from 'vue-recaptcha-v3';





import Swal from 'sweetalert2';
window.Swal = Swal;

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});
const Toast2 = Swal.mixin({
  toast: true,
  position: 'top-end',
  
  
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});
window.Toast = Toast;
window.Toast2 = Toast2;
const app = createApp(App)
app.config.globalProperties.$axios = axios;
app.component('multi-select', Multiselect);
app.component('Navbar', Navbar);
app.component('product-card', roductcard);
window.url = '/es-mx/'
app.use(store);
app.use(router);
app.use(PrimeVue);
app.use(VueReCaptcha, { siteKey: '6LfkjPspAAAAAOo9A9yi__eMXhVELtqN_wH3qQ1N' });
app.directive('tooltip', Tooltip);



app.mount('#app');



