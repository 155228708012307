<style scoped>
#CONTENT_C {
  height: auto;
}

.zmdi {
  color: white;
  font-size: 24px;
  opacity: 0.3;
  position: absolute;
  right: 13px;
  top: 13px;
}


.cuerpo_shop {
  font-size: 14px;

}

.tituloshopping {

  border-top: 1px solid rgba(255, 255, 255, 0.3);
  font-size: 12px;
  margin-top: 25px;

  text-transform: uppercase;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;

}

.card_shopping {
  border-left-color: #06283c;
}

.basket-product {
  width: 100%;
}

input,
button,
.basket,
.basket-module,
.basket-labels,
.item,
.price,
.quantity,
.subtotal,
.basket-product,
.product-image,
.product-details {
  float: left;
  outline: none;
  border: none;
}




.quantity-field {
  background-color: #ccc;
  border: 1px solid #aaa;
  border-radius: 4px;
  font-size: 0.625rem;

  width: 5rem;
}

/*
.quantity {
    text-align: center;
    width: 100%;
  }
  .quantity-field {
    float: none;
  }
  .remove {
    bottom: 0;
    text-align: left;
   
    position: relative;
  }
  .remove button {
    padding: 0;
  }
  
  input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.number-input {
  border: 0;
  display: inline-flex;
}

.number-input,
.number-input * {
  box-sizing: border-box;
}

.number-input button {
  outline:none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1rem;
  cursor: pointer;
  margin: 0;
  position: relative;
  box-shadow: 0px 0px 1px #474747;

}
/*tamaño del + - del boton*/
/*.number-input button:before,
.number-input button:after {
  display: inline-block;
  position: sticky;
  content: '';
  width: 0.5rem;
  height: 1px;
  background-color: #000;
  transform: translate(-50%, -50%);
  border:none;
}
.number-input button.plus:after {
  transform: translate(-50%, -50%) rotate(90deg);
  border:none;
}

/* tamaño del numero */
/*
.number-input input[type=number] {
  font-family: sans-serif;
  max-width: 5rem;
  padding: .5rem;
  border: none;
  border-width: 0 2px;
  font-size: 0.75rem;
  height: 0.75rem;
  font-weight: bold;
  text-align: center;
  color:#cf0a0a;
}
.number-input button.plus {
  margin-bottom: 5px; /* Ajusta según sea necesario para separar los botones 
}*/
/* div tabla */
.divTableCell {
  display: table-row-group;
  display: table-cell;
  width: 40%;
  font-size: 12px;
}

.buttonadd {
  color: white;
  border: 0;
  background: #00adb5;
  margin: 0;
  transition: all 0.1s ease-out;
}

.buttonadd:hover {
  background: #00959c;
}

.vistas {
  color: #ff9900;
  font-weight: bold;
  padding: 0.5em 0 0 1em;
  text-transform: uppercase;
  font-size: 10px;
}

p.stockStatus {
  color: #ff9900;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 10px;
}

p.stockStatus.out {
  color: #CF0A0A;
  font-size: 10px;
}

p.stockStatus.last {
  color: #06283c;
  font-size: 10px;
}

.input-number {
  width: 50%;
  height: 70%;
  font-size: 12px;
  padding: 10px 12px;
  vertical-align: top;
  text-align: center;
  outline: none;
}


.input-number,
.input-number-decrement,
.input-number-increment {
  border: 1px solid #ccc;
  height: 70%;
  user-select: none;

}

.input-number-decrement {
  display: inline-block;
  width: 25%;
  height: 70%;
  line-height: normal;
  background: #f1f1f1;
  color: #444;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

.input-number-increment {
  display: inline-block;
  width: 25%;
  height: 70%;
  line-height: normal;
  background: #f1f1f1;
  color: #444;
  text-align: center;
  font-weight: bold;
  cursor: pointer;

}

&:active {
  background: #ddd;
}


.input-number-decrement {
  border-right: none;
  border-radius: 4px 0 0 4px;

}

.input-number-increment {
  border-left: none;
  border-radius: 0 4px 4px 0;
}
</style>
<template>
  <div class="container-fluid" v-for="existencia in ShoppingCart">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-5">
        <div class="row" style="font-size:9px;justify-content: center">
          <i class="fa fa-industry fa-xs" aria-hidden="true" style="font-size:9px"></i>{{ existencia.nomsucursal }}
        </div>
        <div class="row" v-if="viewexistencias === 0">
          <span v-if="existencia.existencia < 1" style=" font-size: 10x;">
            <p class="stockStatus out">No disponble</p>
          </span>
          <span v-else-if="existencia.existencia > 1" style=" font-size: 10px;">
            <p class="stockStatus">Disponible</p>
          </span>
          <span v-else="existencia.existencia=1" style=" font-size: 10px;">
            <p class="stockStatus last">Última Disponible</p>
          </span>
        </div>
        <div class="row" v-else>
          <span v-if="existencia.existencia < 1" style=" font-size: 10x;">
            <p class="stockStatus out">0 Disponbles</p>
          </span>
          <span v-else-if="existencia.existencia > 1" style=" font-size: 10px;">
            <p class="stockStatus">{{ existencia.existencia }} Disponbles</p>
          </span>
          <span v-else="existencia.existencia=1" style=" font-size: 10px;">
            <p class="stockStatus last">{{ existencia.existencia }} Disponible</p>
          </span>
        </div>
        <!-- Contenido de la primera columna -->
      </div>
      <div class="col-sm-12 col-md-12 col-lg-4">
        <div class="row">
          <span v-if="existencia.ver === 1" style=" font-size: 10px;">
            <p class="vistas">{{ existencia.ver }} persona más lo esta viendo</p>
          </span>
          <span v-else-if="existencia.ver > 1" style=" font-size: 10px;">
            <p class="vistas">{{ existencia.ver }} personas más lo estan viendo</p>
          </span>
        </div>
        <div class="row" v-bind:id="ShoppingCartParte + existencia.sucursal + 'b'">

          <!--
<span v-else-if="GenBack.existencia>1" style=" font-size: 10px;">
                    <p class="stockStatus">Generará Backorder</p>
                  </span>
                  -->


        </div>
        <!-- Contenido de la primera columna -->
      </div>
      <div class="col-sm-12 col-md-12 col-lg-3">
        <div class="row" style="height: auto; margin-top: 2px;margin-bottom: 2px;">
          <span class="input-number-decrement"
            @click="resta(ShoppingCartParte + existencia.sucursal, existencia.existencia, existencia.existenciamx, existencia.mx, existencia.cantidad, existencia.cantidad2)">–</span>
          <input class="input-number" type="number" min="0" v-bind:value="existencia.cantidad"
            v-bind:id="ShoppingCartParte + existencia.sucursal">
          <input class="quantity" min="0" name="quantity2" v-bind:value="existencia.cantidad" type="hidden"
            v-bind:id="'quantity2' + ShoppingCartParte + existencia.sucursal">
          <span class="input-number-increment"
            @click="suma(ShoppingCartParte + existencia.sucursal, existencia.existencia, existencia.existenciamx, existencia.mx, existencia.cantidad2)">+</span>
        </div>
        <div class="row" style="height: auto; margin-top: 2px;margin-bottom: 2px;">
          <button class="btn btn-sm" v-bind:id="'b' + ShoppingCartParte + existencia.sucursal" style="font-size: 10px;color: white; background-color: #ff9900; border-color: #FFF;font-family: sans-serif;
                  border-radius: 5px;" v-if="existencia.cantidad > 0"
            @click="!editMode ? addToCart(ShoppingCartParte + existencia.sucursal, ShoppingCartParte, existencia.sucursal, existencia.existencia, existencia.existenciamx, existencia.mx) : removeToCart(ShoppingCartParte, existencia.sucursal)">
          Agregado
          </button>

          <button class="btn btn-sm" v-bind:id="'b' + ShoppingCartParte + existencia.sucursal" style="font-size: 10px;color: #000; background-color: #BEBEBE; border-color: #FFF;font-family: sans-serif;
                 border-radius: 5px;" v-else="existencia.cantidad=0"
            @click="addToCart(ShoppingCartParte + existencia.sucursal, ShoppingCartParte, existencia.sucursal)">
            Agregar
          </button>
        </div>
        <!--
          <div class="row">
          <button class="btn btn-dark btn-sm" v-if="existencia.existencia > existencia.buynow" @click="pluscarrito()">Buy
            Now</button>
        </div>
        -->
        <!-- Contenido de la primera columna -->
      </div>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2';
export default {
  props: {
    ShoppingCart: {
      type: Object,
    },
    ShoppingCartParte: String,
    viewprecios: Number,
    viewexistencias: Number,
  },
  data() {
    return {
      claveprod: '',
      mensaje: '',
      editMode: false,
      GenBack: 0,
      //viewprecios:true,
      //viewexistencias:true,
    };
  },
  methods: {
    pluscarrito() {
      Swal.fire({
        title: 'Do you want to save the changes?',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Save',
        denyButtonText: `Don't save`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Swal.fire('Saved!', '', 'success')
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info')
        }
      })
    },

    suma(idinputcar, existencia, existenciamx, productomx, cantidadcarrito, existencia2) {
      
      const input = document.getElementById(idinputcar).value;
      const cantidad = document.getElementById('quantity2' + idinputcar).value;
      const button = document.getElementById('b' + idinputcar);
      var sum = 0;
      sum = Number(input) + 1;
      this.GenBack = sum - Number(existencia);

      if (this.GenBack > 1)
        $("#" + idinputcar + "b").val('<span style=" font-size: 10x;"><p class="stockStatus out"  >Backorder: ' + this.GenBack + '</p></span>');
      else
        $("#" + idinputcar + "b").val('');
      $("#" + idinputcar + "").val(Number(sum));

      /*if (Number(cantidad) ===0  ) {
        button.style.fontSize = '11.px';
        button.style.color = '#fff';
        button.style.backgroundColor = '#06283c';
        button.style.borderColor = '#fff';
      }
      else */
      if (Number(sum) === Number(cantidad)) {

        //  $("#b"+idinputcar+"").removeClass("btn-danger");
        // $("#b"+idinputcar+"").addClass("btn-primary");
        button.style.fontSize = '11.px';
        button.style.color = '#fff';
        button.style.backgroundColor = '#ff9900';
        button.style.borderColor = '#fff';
        button.innerHTML = 'Agregado';
      }
      else if (Number(sum) > 0 && Number(cantidad) === 0) {
        button.style.fontSize = '11.px';
        button.style.color = '#333';
        button.style.backgroundColor = '#bebebe';
        button.style.borderColor = '#fff';
        button.innerHTML = 'Agregar';
      }
      else if (Number(cantidad) !== Number(sum) && Number(cantidad) > 0 ) {
        button.style.fontSize = '11.px';
        button.style.color = '#fff';
        button.style.backgroundColor = '#06283c';
        button.style.borderColor = '#fff'
        button.innerHTML = 'Actualizar';

      }

      //this.$store.dispatch('calculaprecios') 

      // this.$emit('destroy', this.reaction);


      //$("#mensaje"+idinputcar+"").html('');

      //console.log(sum); 
    },
    resta(idinputcar, existencia, existenciamx, productomx, cantidadcarrito, cantidad2) {

      const input = document.getElementById(idinputcar).value;
      const cantidad = document.getElementById('quantity2' + idinputcar).value;
      const button = document.getElementById('b' + idinputcar);
      var sum = 0;
      sum = Number(input) - 1;
      sum = Number(sum);
      if (sum < 1) {
        sum = 0;
        this.editMode = true;
      }
      $("#" + idinputcar + "").val(Number(sum));
      if ( Number(cantidad) > 0 && Number(sum) === 0) {
        button.style.fontSize = '11.px';
        button.style.color = '#fff';
        button.style.backgroundColor = '#cf0a0a';
        button.style.borderColor = '#fff'
        button.innerHTML = 'Quitar?';
      }
      else if (Number(sum) > 0 && Number(sum) !== Number(cantidad) && Number(cantidad) > 0) {
        button.style.fontSize = '11.px';
        button.style.color = '#fff';
        button.style.backgroundColor = '#06283c';
        button.style.borderColor = '#fff';
        button.innerHTML = 'Actualizar';
      }
      else if (Number(sum) > 0 && Number(cantidad) === 0) {
        button.style.fontSize = '11.px';
        button.style.color = '#333';
        button.style.backgroundColor = '#bebebe';
        button.style.borderColor = '#fff';
        button.innerHTML = 'Agregar';
      }
      else if (Number(sum) === Number(cantidad) && Number(cantidad) > 0) {
        button.style.fontSize = '11.px';
        button.style.color = '#fff';
        button.style.backgroundColor = '#ff9900';
        button.style.borderColor = '#fff'
        button.innerHTML = 'Agregado';
      }
      /*else if (Number(sum) === 0 && Number(cantidad) === 0) {
        button.style.fontSize = '11.px';s
        button.style.color = '#333';
        button.style.backgroundColor = '#bebebe';
        button.style.borderColor = '#fff';
        button.innerHTML = 'Agregar';
      }
      else if (Number(sum) === Number(cantidad) && Number(cantidad) > 0) {
        button.style.fontSize = '11.px';
        button.style.color = '#fff';
        button.style.backgroundColor = '#ff9900';
        button.style.borderColor = '#fff'
        button.innerHTML = 'Agregado';
      }
      else {
        button.style.fontSize = '11.px';
        button.style.color = '#fff';
        button.style.backgroundColor = '#06283c';
        button.style.borderColor = '#fff'
        button.innerHTML = 'Actualizar';
      }*/


      //this.$store.dispatch('calculaprecios') 
    },
    addToCart(input, producto, sucursal, existencia, existenciamx, productomx) {

      const cantidad = document.getElementById(input).value;
      if (cantidad > 0) {
        /*  Swal.fire({
                  title: `Checkpoint!`,
                  html: `Some Text here.
                  <br> <br> <b>
                      Some text here?
                  </b>
                  <br> <br> <br> 
                      <button type="button" class="btn btn-yes swl-cstm-btn-yes-sbmt-rqst">Yes, Submit Request</button> 
                      <button type="button" class="btn btn-no swl-cstm-btn-no-jst-prceed">No, Just proceed</button> 
                      <button type="button" class="btn btn-cancel swl-cstm-btn-cancel" >Cancel</button>`,
                      showCancelButton: false,
                      showConfirmButton: false,
                      onBeforeOpen: () => {
                          const yes = document.querySelector('.btn-yes')
                          const no = document.querySelector('.btn-no')
                          const cancel = document.querySelector('.btn-cancel')

                          yes.addEventListener('click', () => {
                              console.log('Yes was Cliked.')
                          })
                          no.addEventListener('click', () => {
                              console.log('no was Cliked.')
                          })
                          cancel.addEventListener('click', () => {
                                  console.log('cancel was Cliked.')
                           })
                      }
                  })
  */

        const formData = new FormData();
        formData.append('parte', producto);
        formData.append('cantidad', cantidad);
        formData.append('sucursal', sucursal);
        this.$axios.get('/sanctum/csrf-cookie').then(response => {
          this.$axios.post(`/carrito/addCart`, formData)
            .then(response => {
              this.$store.dispatch('calculaprecios')
              this.$store.dispatch('obtenerCantidadEnCarrito');
              const button = document.getElementById('b' + input);
              button.style.fontSize = '11.px';
              button.style.color = '#fff';
              button.style.backgroundColor = '#ff9900';
              button.style.borderColor = '#fff'
              button.innerHTML = 'Agregado';
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'El producto ' + producto + ' se agrego a tu carrito',
                showConfirmButton: false,
                timer: 1500,
              })

            })
            .catch(function (error) {
              //console.log(error.response.data.message);
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.message,

              })
            });
        }).catch(function (error) {
          //existingObj.strError = "";
          //existingObj.strSuccess = error.response.data.message;
          if (error.response.status === 401 || error.response.status === 500) {
            // El usuario no está autenticado, hacer algo (por ejemplo, redirigir a la página de inicio de sesión)
            Swal.fire({
              title: 'Acceso denegado',
              text: 'Debes iniciar sesión para acceder a esta página.',
              icon: 'warning',
              confirmButtonText: 'Ir a iniciar sesión'
            }).then(() => {
              // Redirigir a la página de inicio de sesión
              window.location.href = '/Ingresa';
            });
          } else {
            // Otro tipo de error, manejarlo según sea necesario
            console.error('Error:', error.message);
          }
        });


      }
    },
    removeToCart(parte, sucursal) {
      const formRemoveData = new FormData();
      formRemoveData.append('parte', parte);
      formRemoveData.append('sucursal', sucursal);
      this.$axios.get('/sanctum/csrf-cookie').then(response => {
        this.$axios.post(`/carrito/removeCart`, formRemoveData)
          .then(response => {
            this.$store.dispatch('cartItems')
            this.$store.dispatch('obtenerCantidadEnCarrito');
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: response.data.message,
              showConfirmButton: false,
              timer: 1500
            })
          })
          .catch(function (error) {

            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: error.response.data.message,
              timer: 1500
            })

          });
      }).catch(function (error) {
        //existingObj.strError = "";
        //existingObj.strSuccess = error.response.data.message;
        if (error.response.status === 401 || error.response.status === 500) {
          // El usuario no está autenticado, hacer algo (por ejemplo, redirigir a la página de inicio de sesión)
          Swal.fire({
            title: 'Acceso denegado',
            text: 'Debes iniciar sesión para acceder a esta página.',
            icon: 'warning',
            confirmButtonText: 'Ir a iniciar sesión'
          }).then(() => {
            // Redirigir a la página de inicio de sesión
            window.location.href = '/Ingresa';
          });
        } else {
          // Otro tipo de error, manejarlo según sea necesario
          console.error('Error:', error.message);
        }
      });


    }, //removetoCart       */
  },
  mounted() {
    //console.log(this.ShoppingCart)
  },
  computed: {

  }
}

</script>
