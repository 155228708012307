import axios from 'axios';

export default {
    state: {
    
        filtered_roles: [],
        filtered_permission_categories: [],
        filtered_permissions: [],
        all_permissions: [],
        filtered_users: [],
    },
    getters: {
       
        filtered_roles(state) {
            return state.filtered_roles;
        },
        filtered_permission_categories(state) {
            return state.filtered_permission_categories;
        },
        filtered_permissions(state) {
            return state.filtered_permissions;
        },
        filtered_users(state) {
            return state.filtered_users;
        },
    },
    mutations: {
     
        set_all_roles: (state, data) => {
            state.filtered_roles = [];
            data.forEach(role => state.filtered_roles.push({value: role.id, label: role.name}));
        },
        set_all_permissions: (state, data) => {
            state.all_permissions = data;
            state.filtered_permission_categories = [];
            let itemsArray = [];
            data.forEach(item => {
                let items = item.name.split('-');
                itemsArray.push(items[0])
            });

            let uniqueItems = [...new Set(itemsArray)];
            state.filtered_permission_categories = uniqueItems
        },
        set_filtered_permissions: (state, data) => {
            state.filtered_permissions = [];
            console.log(data.values)
            data.values.forEach(value => {
                state.all_permissions.find(element => {
                    if(element.name.includes(value)) {
                        state.filtered_permissions.push({value: element.id, label: element.name});
                    }
                });
            });
        },
        set_all_users: (state, data) => {
            state.filtered_users = [];
            window.auth_roles.map(role => {
                if(role.name === 'director') {
                    data.forEach(user => {
                        if(user.id !== window.auth_user.id) {
                            state.filtered_users.push({
                                value: user.id,
                                label: user.name
                            });
                        }
                    });
                }

                if(role.name === 'manager') {
                    data.forEach(user => {
                        user.roles.map(role => {
                            if(user.id !== window.auth_user.id && role.name !== 'director') {
                                state.filtered_users.push({
                                    value: user.id,
                                    label: user.name
                                });
                            }
                        })
                    });
                }
            })
        },
    },
    actions: {
        getAllRoles: (context) => {
            axios.get(`/getAllRoles`).then((response) => {
                context.commit('set_all_roles', response.data)
            });
        },
        getAllPermissions: (context) => {
            axios.get(`/getAllPermissions`).then((response) => {
                context.commit('set_all_permissions', response.data)
            });
        },
        getFilteredPermissions: (context, data) => {
            context.commit('set_filtered_permissions', data);
        },
        getAllUsers: (context, data) => {
            axios.get(`/getAllUsers`).then((response) => {
                context.commit('set_all_users', response.data)
            });
        },
    },
}