<template>
  <div>
    <button class="scroll-top-btn" @click="scrollTop" :class="{ 'visible': showScrollTopBtn }">
      <i class="bx bx-up-arrow-alt"></i> <!-- Icono para el botón de regreso al tope -->
    </button>
    <div class="footer-basic">
      <footer>
        <div class="social">
          <a v-for="red in sucursalesDataPrincipal.redes" :href="getRedSocialLink(red.redsocial, red.valor)"
            target="_blank">
            <i :class="getIconClass(red.redsocial)"></i>
          </a>
        </div>
        <!--<ul class="list-inline">
          <li class="list-inline-item"><a href="#">Home</a></li>
          <li class="list-inline-item"><a href="#">Services</a></li>
          <li class="list-inline-item"><a href="#">About</a></li>
          <li class="list-inline-item"><a href="#">Terms</a></li>
          <li class="list-inline-item"><a href="#">Privacy Policy</a></li>
        </ul>-->
        <p class="copyright">{{ ConfigEmpresa.Nombre }} &copy; {{ ConfigEmpresa.ano }}</p>
      </footer>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showScrollTopBtn: false, // Inicialmente oculto
      sucursalesDataPrincipal: {},
      ConfigEmpresa: {}
    };
  },
  methods: {
    // Método para hacer scroll hacia arriba
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Desplazamiento suave
      });
    },
    // Método para mostrar u ocultar el botón flotante
    handleScroll() {
      if (window.scrollY > 100) {
        this.showScrollTopBtn = true;
      } else {
        this.showScrollTopBtn = false;
      }
    },
    sucursalescontacto() {
      axios.get(`/api/admin/contactosucursales`)
        .then(response => {
          // Manejar la respuesta del servidor
          //console.log('manejo de sucursal')
          //console.log(response)
          this.sucursalesDataPrincipal = response.data.principal
          this.ConfigEmpresa = response.data.ConfigEmpresa
        })
        .catch(error => {
          // Manejar el error en caso de que ocurra
          console.error("Error al actualizar la sucursal:", error);
        });
    },
    getRedSocialLink(redsocial, valor) {
      switch (redsocial) {
        case 'Messenger':
          return `https://m.me/${valor}`;
        case 'Facebook':
          return `https://www.facebook.com/${valor}`;
        case 'Instagram':
          return `https://www.instagram.com/${valor}`;
        case 'WhatsApp':
          const message = 'Hola me gustaría obtener más información';
          return `https://wa.me/${valor}?text=${encodeURIComponent(message)}`;
        case 'X': // Anteriormente Twitter
          return `https://x.com/${valor}`;
        case 'Youtube':
          return `https://www.youtube.com/channel/${valor}`;
        default:
          return '#'; // Enlace predeterminado si no se encuentra la red social
      }
    },
    // Método para obtener la clase del icono de la red social
    getIconClass(redsocial) {
      switch (redsocial) {
        case 'Messenger':
          return 'bx bxl-messenger'; // Reemplaza esto con la clase del icono de Messenger
        case 'Facebook':
          return 'bx bxl-facebook'; // Reemplaza esto con la clase del icono de Facebook
        case 'Instagram':
          return 'bx bxl-instagram'; // Reemplaza esto con la clase del icono de Instagram
        case 'WhatsApp':
          return 'bx bxl-whatsapp'; // Reemplaza esto con la clase del icono de WhatsApp
        case 'X':
          return 'bx bxl-twitter'; // Reemplaza esto con la clase del icono de X
        case 'Youtube':
          return 'bx bxl-youtube'; // Reemplaza esto con la clase del icono de X
        default:
          return ''; // Clase predeterminada si no se encuentra la red social
      }
    }
  },
  mounted() {
    this.sucursalescontacto()
    // Mostrar u ocultar el botón flotante al hacer scroll
    window.addEventListener('scroll', this.handleScroll);

  },
  beforeDestroy() {
    // Eliminar el event listener cuando el componente es destruido
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>
<style scoped>
.footer-basic {

  padding: 40px 0;
  background-color: #ffffff;
  color: #ff9900;
  bottom: 0;
  
}

.footer-basic ul {
  padding: 0;
  list-style: none;
  text-align: center;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 0;
}

.footer-basic li {
  padding: 0 10px;
}

.footer-basic ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.8;
}

.footer-basic ul a:hover {
  opacity: 1;
}

.footer-basic .social {
  text-align: center;
  padding-bottom: 25px;
}

.footer-basic .social>a {
  font-size: 24px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #ccc;
  margin: 0 8px;
  color: inherit;
  opacity: 0.75;
}

.footer-basic .social>a:hover {
  opacity: 0.9;
}

.footer-basic .copyright {
  margin-top: 15px;
  text-align: center;
  font-size: 13px;
  color: #aaa;
  margin-bottom: 0;
}

.scroll-top-btn {
  position: fixed;
  bottom: 120px;
  right: 20px;
  background-color: #ff9900;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  z-index: 1000;
  display: none;
  /* Inicialmente oculto */
  transition: opacity 0.3s ease;
}

.scroll-top-btn.visible {
  display: block;
  /* Mostrar cuando visible */
  opacity: 1;
}

.scroll-top-btn i {
  margin-top: 3px;
  /* Ajusta la posición del icono verticalmente */
}
</style>