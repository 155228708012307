import {createStore} from 'vuex';

import marcasModule from './modulos/detallesprodfiltrado';
import lineasModule from './modulos/detalleslineasprod';
import yearModule from './modulos/detalleslineasyear';
import usersModule from './modulos/users';
import apidataModule from './modulos/apidata';
import ultimatehomeprods from './modulos/homeprods';
import detailsprods from './modulos/detailsprods';
import detailspedidos from './modulos/pedidos';
import cartItems from './modulos/cartItems';
import configuraciones from './modulos/configuraciones';

export const store = createStore({
    strict: true,
    modules: {
        marcasModule,
        lineasModule,
        yearModule,
        usersModule,
        apidataModule,
        ultimatehomeprods,
        detailsprods,
        detailspedidos,
        cartItems,
        configuraciones
    },
    state: {
        current_roles: new Set(),
        current_permissions: new Set(),
    },
    getters: {
        current_roles(state) {
            return state.current_roles
        },
        current_permissions(state) {
            return state.current_permissions
        }
    },
    mutations: {
        get_auth_roles_and_permission: (state) => {
            let roles = window.auth_roles
            let permissions = window.auth_permissions
            console.log(permissions);
            state.current_roles = new Set();
            state.current_permissions = new Set();

            roles.forEach(role => {
                state.current_roles.add(role.name)
            });

            permissions.forEach(permission => {
                state.current_permissions.add(permission.name)
            });
        }
    },
    actions: {
        getAuthRolesAndPermissions: (context) => {
            context.commit('get_auth_roles_and_permission')
        }
    }
});