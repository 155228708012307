import axios from 'axios';

export default {
    state: {
        // departments_test: 0,
        years: {},
    },
    getters: {
        years(state) {
            return state.years
        }
    },
    mutations: {
        set_year: (state, data) => {
            state.years = data
        }
    },
    actions: {
        getYear: (context) => {
            //axios.get(`${window.url}api/getDepartments`).then((response) => {
            axios.get(`/api/getYear`).then((response) => {  
                
                context.commit('set_year', response.data)
            });
        },
        storeYear: (context, yearData) => {
            yearData .post('/api/storeYear').then((response) => {
                context.dispatch('getYear')
                $('#modalyear').modal('hide')
            });
        },
        updateLineaMarca:(context, idcarga)=>{
            idcarga.post('/api/cargamarca').then((response) => {
            window.Toast.fire({
                icon: 'success',
                title: response.data.mensaje
            });
            context.dispatch('getLinea')
            });
        }
      
    },
}