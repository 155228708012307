import axios from 'axios';

export default {
    state: {
        // departments_test: 0,
        marcas: {},
    },
    getters: {
        marcas(state) {
            return state.marcas
        }
    },
    mutations: {
        set_marca: (state, data) => {
            state.marcas = data
        }
    },
    actions: {
        getMarca: (context) => {
            //axios.get(`${window.url}api/getDepartments`).then((response) => {
            axios.get(`/api/getMarca`).then((response) => {  
                
                context.commit('set_marca', response.data)
            });
        },
        storeMarca: (context, marcaData) => {
            marcaData.post('/api/storeMarca').then((response) => {
                context.dispatch('getMarca')
                $('#modalmarcas').modal('hide')
            });
        },
        updateMarca: (context, marcaData) => {
            marcaData.post('/api/updateMarca/' + marcaData.id).then((response) => {
                context.dispatch('getMarca')
                $('#modalmarcas').modal('hide')
            });
        },
        deleteMarca: (context, marcaData) => {
            if(confirm('Esta seguro que desea eliminar la Marca?')) {
                axios.post('/api/deleteMarca/' + marcaData.id).then(() => {
                    context.dispatch('getMarca')
                });
            }
        }
    },
}