import axios from 'axios';

export default {
    state: {
        cartItems: {},
        viasEmbarque: {},
        detallePedidos: {},
        detalleDirEnvios: {},
        montosub: 0,
        totalpiezas: 0,
        iva: 0,
        total: 0,
        viacliente: '',
    },
    getters: {
        cartItems(state) {
            return state.cartItems
        },
        montosub(state) {
            return state.montosub
        },
        totalpiezas(state) {
            return state.totalpiezas
        },
        iva(state) {
            return state.iva
        },
        total(state) {
            return state.total
        }
        ,
        viasEmbarque(state) {
            return state.viasEmbarque
        },
        viacliente(state) {
            return state.viacliente
        },
        detallePedidos(state) {
            return state.detallePedidos
        },
        detalleDirEnvios(state) {
            return state.detalleDirEnvios
        }

    },
    mutations: {
        set_cartItems: (state, data) => {
            state.cartItems = data
        },
        set_viasEmbarque: (state, data) => {
            state.viasEmbarque = data
        },
        set_viacliente: (state, data) => {
            state.viacliente = data
        },
        set_totalpiezas: (state, data) => {
            let suma = 0;
            data.forEach(function (item) {
                item.ShoppingCart.forEach(function (sucursal) {
                    if (sucursal.cantidad > 0) {
                        suma += Number(sucursal.cantidad)
                    }

                })
            });


            state.totalpiezas = suma
        },
        set_montosub: (state, data) => {
            let subtotal = 0;
            let suma = 0;

            data.forEach(function (item) {
                item.ShoppingCart.forEach(function (sucursal) {
                    if (sucursal.cantidad > 0) {
                        subtotal += Number(item.precio_ajuste.valor) * Number(sucursal.cantidad)
                    }

                })
            });


            state.iva = (subtotal * 0.16).toFixed(2)
            state.montosub = subtotal.toFixed(2)
            state.total = (subtotal * 1.16).toFixed(2)
        },
        set_detallePedidos: (state, data) => {
            state.detallePedidos = data
        },
        set_detalleDirEnvios: (state, data) => {
            state.detalleDirEnvios = data
        }
    },
    actions: {
        cartItems: (context) => {
            axios.get('/getItems').then((response) => {

                console.log(response.data.viacliente);
                //context.commit('set_cartItems', response.data.carrito)
                context.commit('set_montosub', response.data.carrito)
                context.commit('set_totalpiezas', response.data.carrito)
                context.commit('set_viasEmbarque', Object.values(response.data.vias))
                context.commit('set_viacliente', response.data.viacliente.toString())

                //context.commit('set_detailsaplicaciones', response.data[0].aplicaciones)
                //context.commit('set_aplicaciones', data2)

            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    window.Toast.fire({
                        title: 'Sesión Expirada',
                        text: 'Tu sesión ha expirado. Por favor, inicia sesión nuevamente.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then(() => {
                        // Redirigir al usuario a la página de inicio de sesión si es necesario
                        window.location.href = '/login'; // Ajusta la URL de inicio de sesión según tu aplicación
                    });
                } else {
                    console.log(error);
                }
            });

        },
        calculaprecios: (context) => {
            axios.get('/cartItems/getItems/').then((response) => {

                console.log(response.data);
                //   context.commit('set_cartItems', response.data)
                context.commit('set_montosub', response.data.carrito)
                context.commit('set_totalpiezas', response.data.carrito)
                //context.commit('set_detailsaplicaciones', response.data[0].aplicaciones)
                //context.commit('set_aplicaciones', data2)

            }).catch((error) => {
                console.log(error)
            });

        },
        generaCarrito: (context, datosCarrito) => {
            axios.post('/cartItems/generacionCarrito/', datosCarrito).then((response) => {
                console.log(response.data);
                context.commit('set_detallePedidos', response.data.procesado)
                context.commit('set_detalleDirEnvios', response.data.direnvio)

                $router.push({ name: 'DetallePrepedido' });
            }).catch((error) => {
                console.log(error)
            });

        },
    },


}