import axios from 'axios';

export default {
    state: {
        users: [],
        userLinks: [],
        userinfo:{},
        facturas:{},
        cliente:'',
        producVenNoComp:{},
        proBusOClient:{},
        factvenc:0,
        saldo:0,
        empresas:{},
        docfactvenc:{},
        empresasOrigen:{},
        isLoggedin: window.Laravel.isLoggedin || false,
    },
    getters: {
        isLoggedin: (state) => state.isLoggedin,
        users(state) {
            return state.users
        },
        userLinks(state) {
            return state.userLinks
        },
        userinfo(state) {
            return state.userinfo
        },
        facturas(state) {
            return state.facturas
        },
        cliente(state) {
            return state.cliente
        },
        producVenNoComp(state) {
            return state.producVenNoComp
        },
        proBusOClient(state) {
            return state.proBusOClient
        },
        factvenc(state){
            return state.factvenc
        },
        saldo(state){
            return state.saldo
        },
        empresas(state){
            return state.empresas
        },
        docfactvenc(state){
            return state.docfactvenc
        },
        empresasOrigen(state){
            return state.empresasOrigen
        }
    },
    mutations: {
        setLoginStatus(state, status) {
            state.isLoggedin = status;
        },
        set_userinfo: (state, data) => {
            state.userinfo = data
        },
        set_cliente: (state, data) => {
            state.cliente = data
        },
        set_factvenc:(state, data) => {
             
            state.factvenc = data  
        },
        set_saldo:(state, data) => {
             
            state.saldo = data  
        },
        set_facturas:(state, data) => {
             
            state.facturas = data  
        },
        set_empresas:(state, data) => {             
            state.empresas = data  
        },
        set_producVenNoComp: (state, data) => {
            state.producVenNoComp = data
        },
        set_proBusOClient: (state, data) => {
            state.proBusOClient = data
        },
        set_users: (state, data) => {
            state.users = data
            /*state.userLinks = [];

            for(let i = 0; i < data.links.length; i++) {
                if(i === 1
                    || i === Number(data.links.length - 2)
                    || data.links[i].active
                    || isNaN(data.links[i].label)
                    || Number(data.links[i].label) === Number(data.current_page + 1)
                    || Number(data.links[i].label) === Number(data.current_page - 1)
                    ) {
                        state.userLinks.push(data.links[i]);
                    }
            }*/
        },
        set_docfactvenc:(state,data) =>{
            state.docfactvenc=data
        },
        set_empresasOrigen:(state,data)=>{
            state.empresasOrigen=data
        }
    },
    actions: {
        updateLoginStatus({ commit }, status) {
            commit('setLoginStatus', status);
        },
        userinfo: (context) => {
            setTimeout(function() {
                axios.get(`/userinfo`).then((response) => {
                    console.log(response.data)
                    //context.commit('set_facturas', response.data[0].external.PRECIOS)
                    context.commit('set_userinfo', response.data.datos.ClienteWeb.Cliente)
                    context.commit('set_cliente', response.data.datos.ClienteWeb.Cliente.cliente)
                    context.commit('set_facturas', response.data.facturas)
                    context.commit('set_factvenc', response.data.vencido)
                    context.commit('set_docfactvenc', response.data.facvencidas)
                    context.commit('set_saldo', response.data.total)
                    context.commit('set_empresas', response.data.empresas)
                    context.commit('set_empresasOrigen', response.data.empresasOrigen)
                }).catch(err => {
                    console.log(err);
                    if (error.response && error.response.status === 401) {
                        // Mostrar alerta de sesión expirada
                        Swal.fire({
                            title: 'Sesión expirada',
                            text: 'Tu sesión ha expirado. Por favor, ingresa nuevamente.',
                            icon: 'warning',
                            confirmButtonText: 'Aceptar',
                        }).then(() => {
                            // Redirigir a la página de inicio de sesión
                            window.location.href = `${window.location.origin}/Ingresa`;
                        });
                    } else {
                        console.log(error);
                    }
                });
            });
        }, 
        searchUser: (context, searchData) => {
            setTimeout(function() {
                axios.get(`api/searchUser?${searchData.search_type}=${searchData.search_value}`).then((response) => {
                    context.commit('set_users', response.data)
                }).catch(err => {
                    console.log(err);
                });
            });
        },
        getUsersResults: (context) => {
            //console.log(link)
            axios.get(`/api/getUsers`).then((response) => {
                context.commit('set_users', response.data)
            });
        },
        getUsers: (context) => {
            axios.get(`/api/getUsers`).then((response) => {
                console.log(response.data)
                context.commit('set_users', response.data)
            });
        },
        storeUser: (context, userData) => {
            userData.post('/api/storeUser').then((response) => {
                context.dispatch('getUsers')
                $('#exampleModal').modal('hide')

                window.Toast.fire({
                    icon: 'success',
                    title: 'User created successfully!'
                });
            });
        },
        updateUser: (context, userData) => {
            userData.post('/api/updateUser/' + userData.id).then((response) => {
                context.dispatch('getUsers')
                $('#exampleModal').modal('hide')

                window.Toast.fire({
                    icon: 'success',
                    title: 'User updated successfully!'
                }).catch(err => {
                    console.log(err);
                });
            
            });
        },
        deleteUser: (context, userData) => {
            axios.post('/api/deleteUser/' + userData.id).then(() => {
                context.dispatch('getUsers')
                window.Toast.fire({
                    icon: 'success',
                    title: 'User deleted successfully!'
                });
            });
        },
        producVenNoComp: (context) => {
            setTimeout(function() {
                axios.get(`/controllerpeticiones/producvendidosNocomp/productosmvnc`).then((response) => {
                    //console.log(response.data.filtrado)
                    context.commit('set_producVenNoComp', response.data.filtrado)
                    //context.commit('set_cliente', response.data[0].external.PRECIOS.Cliente)
                }).catch(err => {
                    console.log(err);
                });
            });
        },
        proBusOClientes: (context) => {
            setTimeout(function() {
                axios.get(`/controllerpeticiones/producBuscotrosClient/buscadosc`).then((response) => {
                    //console.log(response.data)
                    context.commit('set_proBusOClient', response.data.filtrado)
                    
                    //context.commit('set_cliente', response.data[0].external.PRECIOS.Cliente)
                }).catch(err => {
                    console.log(err);
                });
            });
        },  
    },
    methods(){
        function formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
        
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;
        
            return [year, month, day].join('-');
        }
    }
}