<style scope>
.wrapper {
  height: 500px;
  margin:auto;
  width: 850px;
  margin-top: 5%;
  border-radius: 7px;
  box-shadow: 0 20px 50px rgba(255,255,255,0.15);
  background: rgba(255, 255, 255, 0.75);
  border: 2px solid #3c1361;
    z-index: 10;
}
.wrapper img{
  float: left;
  width: 380px;
  height: 496px;
  border-radius: 7px 0 0 7px;
}
.product-new-label{
    color: #000;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;

}
.product-info {
  float: right;
  height: 500px;
  width: 450px;
  padding: 20px;
}
.wrapper h1,h4,h5,p{
  display: flex;
  justify-content: center;
}
.rating{
    display:flex;
    justify-content: center;
}
.rating span{
  font-size:20px;
}

.price{
  font-size: 20px;
  font-weight: 400;
}

.desc{
  line-height: 1.6;
  font-size: 18px;
  width: 400px;
}
.product-price-btn {
  display:flex;
  justify-content: space-around;
  padding: 20px;
}
.product-price-btn button {
  padding:18px;
  border: transparent;
  border-radius: 20px;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
}

.product-price-btn button i{
    margin-right: 8px;
}
.add-cart-btn{
    background-color: #000;
    color: #fff;
}
.add-cart-btn:hover,.buy-now-btn:hover{
    background-color: #fff;
    color: #000;
    box-shadow:0 0 5px grey;
}
.buy-now-btn{
    background-color: #000;
    color:#fff;
}

</style>
<template>
  <div class="content">
    <h1>Lista de Resultados por: "{{ claveprod }}"</h1>

  
    <div class="wrapper" v-for="(item, index) in getProductosListado">
     
      <div class="product-img"  v-if="item.PicturesUrl.length > 0 || item.PicturesUrl[4]">
        <img v-bind:src='item.PicturesUrl[4].sm' alt=""  />
      </div>
    <div class="product-img" v-else>
        <img src='img/else/120x166' alt="Placholder Image 2" class="product-frame">
    </div>
    <span class="product-new-label">New</span>

    <div class="product-info">
      <div class="product-text">
        <h1> <router-link :to="{name:'verprods', params: {id:item.k_parte}}" class="btn btn-outline-danger"
                style="font-size:22px;border: none">{{ item.k_parte }}</router-link></h1>
        <h4 v-if="item.precio_ajuste && (viewprecios === '' || viewprecios === null || viewprecios === 1)" style="text-align: end;"><strong>{{ item.precio_ajuste.prefijo }} {{ item.precio_ajuste.valor }}{{ item.precio_ajuste.sufijo }} </strong>
                  </h4>
          <p class="price" v-if="item.precio_ajuste"></p>
          <div class="rating" ><h5> {{ item.linea }} </h5>
            
            <!--
                <span><i class="fa fa-star"></i></span>
                <span><i class="fa fa-star"></i></span>
                <span><i class="fa fa-star"></i></span>
                <span><i class="fa fa-star"></i></span>
                <span><i class="fa fa-star-half-alt"></i></span>  
                <span>(250 rating)</span>
            -->
              
            </div>
          <p class="desc" v-html="formatItem(item.descripcion)"></p>
      </div>
      <div class="product-price-btn">
               <!--
<button type="button" class="add-cart-btn">
                    <i class="fa fa-shopping-cart"></i>Add to cart
                </button>
                <button type ="button" class="buy-now-btn">
                    <i class="fa fa-wallet"></i>Buy Now
                </button>
               --> 
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import car from '../Prods/carShop';
export default {
  components: { car },
  data() {
    return {
      items: [],
      claveprod: '',
      viewprecios: window.Laravel.verprecios,
    }
  },
  methods: {
    formatItem(value) {
      // Reemplazar "/" con "<br>" para los saltos de línea
      if (!value) {
        return ""; // O manejar el caso en que los datos no son válidos
      }
      //console.log(value)
      //const boldLetters = value.replace(/([A-Z]+)/g, '<strong>$1</strong>');
      const formattedText = value.replace(/\s*\/\s*/g, "<br>");
      
      return formattedText;
      
    },
    updatePublished() {

    },
    updateTutorial() {
    },
    deleteTutorial() {
    },
  },
  mounted() {
    this.claveprod = this.$route.params.prod;
    //console.log(this.claveprod);
    this.$store.dispatch('getProductosListado', this.claveprod)
  }, computed: {
    getProductosListado() {
      return this.$store.getters.getProductosListado;
    },
  }
}
</script>
