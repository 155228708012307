import axios from 'axios';

export default {
    state: {
        // departments_test: 0,
        ultimateprods: {},
        banners:{},
        prodsbanner:{},
        prodsbannerbestbuy:{},
        obtenerCantidadEnCarrito:0
    },
    getters: {
        ultimateprods(state) {
            return state.ultimateprods
        },
        banners(state) {
            return state.banners
        },
  
        prodsbanner(state){
            return state.prodsbanner
        },
        prodsbannerbestbuy(state){
            return state.prodsbannerbestbuy
        },
        obtenerCantidadEnCarrito(state){
            return state.obtenerCantidadEnCarrito
        }
    },
    mutations: {
        set_ultimateprods: (state, data) => {
            state.ultimateprods = data
        },
        set_prodsbanner:(state, data)=>{
            state.prodsbanner = data
        },
        set_banners: (state, data) => {
            state.banners = data
        },
   
        set_prodsbannerbestbuy:(state, data)=>{
            state.prodsbannerbestbuy = data
        },
        set_obtenerCantidadEnCarrito:(state,data)=>{
           state.obtenerCantidadEnCarrito=parseInt(data, 10)
        }
    },
    actions: {
        ultimateprods: (context) => {
            axios.get('/api/ultimosproductos').then((response) => {
                //console.log(response.data);
                context.commit('set_ultimateprods', response.data)
            });
            
        },
        banners: (context) => {
            axios.get('/api/banners').then((response) => {
                console.log(response.data);
                context.commit('set_banners', response.data)
            });
            
        },
       
        prodsbanner:(context) =>{
            axios.get('/api/controllerpeticiones/prodsbanner').then((response) => {
                
                context.commit('set_prodsbanner', response.data.filtrado)      
            });
        },
        prodsbannerbestbuy:(context) =>{
            axios.get('/api/controllerpeticiones/prodsbannerbestbuy').then((response) => {
                context.commit('set_prodsbannerbestbuy', response.data.filtrado)      
            });
        },
        obtenerCantidadEnCarrito:(context)=>{
            axios.get('/cartItemscantidadEnCarrito').then((response) => {
                console.log(response.data)
                context.commit('set_obtenerCantidadEnCarrito', parseInt(response.data.cantidad))   
              })
                .catch(function (error) {
                  console.log(error);
                  //document.getElementById('marca').style.display = 'none';
                });
        }
        
    },
}